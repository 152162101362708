import tippy from 'tippy.js';

function initTooltips() {
    const tooltips = document.querySelectorAll('.js-tooltip-button');
    tooltips.forEach((tooltip) => {
        tippy(tooltip, {
            theme: 'juve',
            placement: 'auto',
            trigger: 'click',
            offset: [10, 10],
        });
    });
}

initTooltips();

window.addEventListener('init-tippy-tooltips', () => {
    initTooltips();
});
